import {focusTrap} from '@primer/behaviors'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import type {EmojiPickerElement} from '../emoji-picker-element'

window.addEventListener('action-menu-item-toggle', function (event) {
  if (!(event instanceof CustomEvent)) return
  const item = event.detail.item
  const action = event.detail.action
  let data
  if (!(item instanceof HTMLElement)) return
  const target = item.parentElement
  if (!(target instanceof HTMLElement)) return

  if (action === 'expanded') {
    data = {category: 'discussion_section', action: 'collapse', label: 'discussion_section_collapsed'}
  } else {
    data = {category: 'discussion_section', action: 'expand', label: 'discussion_section_expanded'}
  }

  target.setAttribute('data-analytics-event', JSON.stringify(data))
})

on('click', '.js-discussion-category-emoji-button', function (event) {
  if (!(event.currentTarget instanceof HTMLButtonElement)) return
  const container = getDiscussionCategoryContainer(event)

  const emojiField = container.querySelector<HTMLInputElement>('.js-discussion-category-emoji-field')!
  emojiField.value = event.currentTarget.value

  const emojiSpan = container.querySelector<HTMLElement>('.js-discussion-category-custom-emoji')!
  emojiSpan.innerHTML = event.currentTarget.innerHTML

  for (const previouslySelected of container.querySelectorAll<HTMLButtonElement>('.selected-emoji')) {
    previouslySelected.classList.remove('selected-emoji')
  }
  event.currentTarget.classList.add('selected-emoji')

  container.querySelector<HTMLElement>('.js-discussion-category-emoji-picker')?.hidePopover()
})

const traps: WeakMap<HTMLElement, AbortController | undefined> = new WeakMap()

// Workaround for Safari bug where popover does not toggle, will be fixed with https://github.com/WebKit/WebKit/pull/30187
on('click', '.js-discussion-category-form button[popovertarget]', function (event) {
  const button = event.currentTarget as HTMLElement
  const target = button.getAttribute('popovertarget')
  const popover = target ? document.getElementById(target) : null

  if (!(popover instanceof HTMLElement)) return

  popover.togglePopover()
  event.preventDefault()
})

on(
  'toggle',
  '.js-discussion-category-emoji-picker',
  function (event) {
    const {newState, currentTarget: popover} = event as unknown as ToggleEvent

    if (!(popover instanceof HTMLElement)) return

    const picker: EmojiPickerElement | null = popover.querySelector('emoji-picker')

    switch (newState) {
      case 'open':
        traps.set(popover, focusTrap(popover))
        picker?.open()
        break
      case 'closed':
        traps.get(popover)?.abort()
        traps.delete(popover)
        picker?.close()
        break
    }
  },
  {capture: true},
)

on('click', '.js-announcement-radio-button', function (event) {
  const currentCategory = event.currentTarget.closest('.js-category-container')!
  const currentCategoryId = currentCategory.getAttribute('data-category-id')!

  if (currentCategory.getAttribute('data-supports-announcements') === 'false') {
    const announcementWarning = document.getElementById(`${currentCategoryId}-announcement-warning`)!
    if (announcementWarning) {
      announcementWarning.removeAttribute('hidden')
    }
    const markAsAnswerWarning = document.getElementById(`${currentCategoryId}-mark-as-answer-warning`)!
    if (markAsAnswerWarning) {
      markAsAnswerWarning.setAttribute('hidden', 'true')
    }
  }
})

on('click', '.js-mark-as-answer-radio-button', function (event) {
  const currentCategory = event.currentTarget.closest('.js-category-container')!
  const currentCategoryId = currentCategory.getAttribute('data-category-id')!

  const markAsAnswerWarning = document.getElementById(`${currentCategoryId}-mark-as-answer-warning`)
  if (markAsAnswerWarning) {
    markAsAnswerWarning.setAttribute('hidden', 'true')
  }
  const announcementWarning = document.getElementById(`${currentCategoryId}-announcement-warning`)
  if (announcementWarning) {
    announcementWarning.setAttribute('hidden', 'true')
  }
})

on('click', '.js-open-ended-radio-button', function (event) {
  const button = event.currentTarget as HTMLInputElement
  const currentCategory = event.currentTarget.closest('.js-category-container')!
  const currentCategoryId = currentCategory.getAttribute('data-category-id')!
  const selectedCategory = button.value
  const otherDiscussion =
    currentCategory.getAttribute('data-supports-mark-as-answer') === 'false' &&
    currentCategory.getAttribute('data-supports-announcements') === 'false'

  if (currentCategory.getAttribute('data-supports-mark-as-answer') === 'true') {
    const markAsAnswerWarning = document.getElementById(`${currentCategoryId}-mark-as-answer-warning`)
    if (markAsAnswerWarning && selectedCategory !== 'mark_as_answer') {
      markAsAnswerWarning.removeAttribute('hidden')
      const announcementWarning = document.getElementById(`${currentCategoryId}-announcement-warning`)!
      announcementWarning.setAttribute('hidden', 'true')
    }
  }

  if (otherDiscussion) {
    const announcementWarning = document.getElementById(`${currentCategoryId}-announcement-warning`)!
    if (announcementWarning) {
      announcementWarning.setAttribute('hidden', 'true')
    }
    const markAsAnswerWarning = document.getElementById(`${currentCategoryId}-mark-as-answer-warning`)!
    if (markAsAnswerWarning) {
      markAsAnswerWarning.setAttribute('hidden', 'true')
    }
  }
})

function getDiscussionCategoryContainer(event: Event & {currentTarget: Element}): HTMLElement {
  return event.currentTarget.closest<HTMLElement>('.js-discussion-category-form')!
}
