// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {parseHTML} from '@github-ui/parse-html'

const sendRequest = async (url: string, csrfToken: string, question: string, options: string[]) => {
  const form = new FormData()
  form.append('question', question)
  for (const option of options) {
    form.append('options[]', option)
  }

  return await fetch(url, {
    body: form,
    method: 'POST',
    mode: 'same-origin',
    headers: {
      'Scoped-CSRF-Token': csrfToken,
    },
  })
}

class PollFormElement {
  pollForm: HTMLElement
  pollPreviewUrl: string

  constructor(pollForm: HTMLElement) {
    this.pollForm = pollForm
    this.pollPreviewUrl = this.pollForm.getAttribute('data-poll-preview-url') || ''
  }

  hide(): void {
    this.pollForm.hidden = true
    this.pollForm?.setAttribute('aria-hidden', 'true')
  }

  show(): void {
    this.pollForm.hidden = false
    this.pollForm?.setAttribute('aria-hidden', 'false')
  }

  getCsrfInputValue(): string {
    const csrfInput = this.pollForm.querySelector<HTMLInputElement>('.js-poll-preview-url-post-csrf')
    return csrfInput ? csrfInput.value : ''
  }

  getOptions(): string[] {
    const options: string[] = []
    const pollOptionsContainer = this.pollForm.querySelector<HTMLElement>('#poll-option-container')
    if (!pollOptionsContainer) return options
    const pollOptionInputElements = pollOptionsContainer.querySelectorAll<HTMLInputElement>('.js-poll-option')
    for (const element of pollOptionInputElements) {
      if (element.value) options.push(element.value)
    }
    return options
  }

  getQuestion(): string {
    const questionInputElment = this.pollForm.querySelector<HTMLInputElement>('.js-poll-form-input-question')
    if (!questionInputElment) return ''
    return questionInputElment.value
  }
}

class Poll {
  pollFormElement: PollFormElement

  constructor(pollFormElement: PollFormElement) {
    this.pollFormElement = pollFormElement
  }

  hidePollForm(): void {
    this.pollFormElement.hide()
  }

  showPollForm(): void {
    this.pollFormElement.show()
  }

  async getPreview(): Promise<string> {
    const question = this.pollFormElement.getQuestion()
    const options = this.pollFormElement.getOptions()

    if (question && options.length > 0) {
      const response = await sendRequest(
        this.pollFormElement.pollPreviewUrl,
        this.pollFormElement.getCsrfInputValue(),
        question,
        options,
      )
      return await response.text()
    }
    return ''
  }
}

let poll: Poll | null

observe('.js-discussion-poll-form-component', discussionPollElement => {
  poll = new Poll(new PollFormElement(discussionPollElement as HTMLElement))
})

on('preview:rendered', '.js-previewable-comment-form', async function (event) {
  if (!poll) return
  const target = event.currentTarget
  const commentBody = target.querySelector<HTMLElement>('.comment-body')!
  const previewPollHTML = await poll.getPreview()
  commentBody.append(parseHTML(document, previewPollHTML))
  poll.hidePollForm()
})

on('poll-preview:toggle:off', '.js-discussion-poll-form-component', async function () {
  if (!poll) return
  poll.showPollForm()
})
