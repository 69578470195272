import memoize from '@github/memoize'
import {hasMatch} from 'fzy.js'
import {
  type SearchProvider,
  type FilterProvider,
  FilterItem,
  type QueryEvent,
  type QueryFilterElement,
  SearchItem,
  Octicon,
} from '@github-ui/query-builder-element/query-builder-api'
import type {QueryBuilderElement} from '@github-ui/query-builder-element'

interface Suggestion {
  value: string
  description?: string
}

async function fetchJSON(url: string) {
  const response = await fetch(url, {headers: {Accept: 'application/json'}})

  if (response.ok) {
    return await response.json()
  } else {
    return undefined
  }
}
const memoizeCache = new Map()
const memoizeFetchJSON = memoize(fetchJSON, {cache: memoizeCache})

const searchInputId = 'query-builder-discussions-search-combobox'

function getSearchInput(): HTMLElement {
  return document.querySelector<HTMLElement>(`query-builder#${searchInputId}`)!
}

async function fetchAuthorSuggestions(): Promise<Suggestion[]> {
  const url = getSearchInput().getAttribute('data-suggestable-authors-path')!
  return memoizeFetchJSON(url)
}

async function fetchCategorySuggestions(): Promise<Suggestion[]> {
  return JSON.parse(getSearchInput().getAttribute('data-suggestable-categories')!)
}

async function fetchLabelSuggestions(): Promise<Suggestion[]> {
  const url = getSearchInput().getAttribute('data-suggestable-labels-path')!
  return memoizeFetchJSON(url)
}

const authors = async (): Promise<Suggestion[]> => await fetchAuthorSuggestions()
const categories = async (): Promise<Suggestion[]> => await fetchCategorySuggestions()
const labels = async (): Promise<Suggestion[]> => await fetchLabelSuggestions()
const states = ['open', 'closed', 'answered', 'unanswered', 'locked', 'unlocked']
const getSearchUrl =
  document.querySelector<HTMLFormElement>('#discussions-search-combobox-form')?.getAttribute('action') || ''

const filterSuggestions = (suggestions: string[], query: string) =>
  suggestions.filter(suggestion => {
    if ((query && hasMatch(query, suggestion)) || query === '') return suggestion
  })

class SearchItemProvider extends EventTarget implements SearchProvider {
  priority = 1
  name = 'Search'
  singularItemName = 'search item'
  value = 'search'
  type = 'search' as const

  constructor(public queryBuilder: QueryBuilderElement) {
    super()

    this.queryBuilder.addEventListener('query', this)
    this.queryBuilder.attachProvider(this)
  }

  handleEvent(event: QueryEvent) {
    if (String(event) === '') return
    this.dispatchEvent(
      new SearchItem({
        priority: 1,
        value: event.toString(),
        icon: Octicon.Search,
        scope: 'GENERAL',
        action: {
          url: `${getSearchUrl}?discussions_q=${event.toString()}`,
        },
      }),
    )
  }
}

class AuthorProvider extends EventTarget implements FilterProvider {
  name = 'Authors'
  singularItemName = 'author'
  value = 'author'
  priority = 2
  type = 'filter' as const

  constructor(public queryBuilder: QueryBuilderElement) {
    super()

    this.queryBuilder.addEventListener('query', this)
    this.queryBuilder.attachProvider(this)
  }

  async handleEvent(event: QueryEvent) {
    const lastQuery = event.parsedQuery.at(-1)
    const lastQueryValue = lastQuery?.value || ''
    const lastQueryType = lastQuery?.type
    const lastQueryFilter = (lastQuery as QueryFilterElement)?.filter || ''

    if (lastQueryType !== 'filter' && (hasMatch(lastQueryValue, this.value) || lastQueryValue === '')) {
      this.dispatchEvent(new Event('show'))
    }

    // Prevents the author filters from being fetched if the author filter was not chosen
    if (lastQueryType !== 'filter' || lastQueryFilter !== this.value) return

    const getAuthorData = await authors()

    const authorValues = getAuthorData.map(author => author.value)
    const filteredAuthors = filterSuggestions(authorValues, lastQueryValue)

    // Only show the top 5 results to avoid overwhelming the browser,
    // fixes https://github.com/github/discussions/issues/2816
    const limitedFilteredAuthors = filteredAuthors.slice(0, 5)

    for (const author of limitedFilteredAuthors) {
      this.dispatchEvent(new FilterItem({filter: 'author', value: author}))
    }
  }
}

class CategoryProvider extends EventTarget implements FilterProvider {
  name = 'Categories'
  singularItemName = 'category'
  value = 'category'
  priority = 3
  type = 'filter' as const

  constructor(public queryBuilder: QueryBuilderElement) {
    super()

    this.queryBuilder.addEventListener('query', this)
    this.queryBuilder.attachProvider(this)
  }

  async handleEvent(event: QueryEvent) {
    const lastQuery = event.parsedQuery.at(-1)
    const lastQueryValue = lastQuery?.value || ''
    const lastQueryType = lastQuery?.type
    const lastQueryFilter = (lastQuery as QueryFilterElement)?.filter || ''

    if (lastQueryType !== 'filter' && (hasMatch(lastQueryValue, this.value) || lastQueryValue === '')) {
      this.dispatchEvent(new Event('show'))
    }

    if (lastQueryType !== 'filter' || lastQueryFilter !== this.value) return

    const getCategoryData = await categories()

    const categoryValues = getCategoryData.map(category => {
      const valueNoQuotes = category.value?.replace(/"/g, '')
      return valueNoQuotes
    })

    const filteredCategories = filterSuggestions(categoryValues, lastQueryValue)

    for (const category of filteredCategories) {
      this.dispatchEvent(new FilterItem({filter: 'category', value: category}))
    }
  }
}

class StateProvider extends EventTarget implements FilterProvider {
  name = 'States'
  singularItemName = 'state'
  value = 'is'
  priority = 4
  type = 'filter' as const

  constructor(public queryBuilder: QueryBuilderElement) {
    super()

    this.queryBuilder.addEventListener('query', this)
    this.queryBuilder.attachProvider(this)
  }

  handleEvent(event: QueryEvent) {
    const lastQuery = event.parsedQuery.at(-1)
    const lastQueryValue = lastQuery?.value || ''
    const lastQueryType = lastQuery?.type
    const lastQueryFilter = (lastQuery as QueryFilterElement)?.filter || ''

    const filteredStates = filterSuggestions(states, lastQueryValue)

    if (lastQueryType !== 'filter' && (hasMatch(lastQueryValue, this.value) || lastQueryValue === '')) {
      this.dispatchEvent(new Event('show'))
    }

    if (lastQueryType !== 'filter' || lastQueryFilter !== this.value) return

    for (const state of filteredStates) {
      this.dispatchEvent(new FilterItem({filter: 'is', value: state}))
    }
  }
}

class LabelProvider extends EventTarget implements FilterProvider {
  name = 'Labels'
  singularItemName = 'label'
  value = 'label'
  priority = 5
  type = 'filter' as const

  defaultLabels = [
    'bug',
    'documentation',
    'duplicate',
    'enhancement',
    'good first issue',
    'help wanted',
    'invalid',
    'question',
    'wontfix',
  ]

  constructor(public queryBuilder: QueryBuilderElement) {
    super()

    this.queryBuilder.addEventListener('query', this)
    this.queryBuilder.attachProvider(this)
  }

  async handleEvent(event: QueryEvent) {
    const lastQuery = event.parsedQuery.at(-1)
    const lastQueryValue = lastQuery?.value || ''
    const lastQueryType = lastQuery?.type
    const lastQueryFilter = (lastQuery as QueryFilterElement)?.filter || ''

    if (lastQueryType !== 'filter' && (hasMatch(lastQueryValue, this.value) || lastQueryValue === '')) {
      this.dispatchEvent(new Event('show'))
    }

    if (lastQueryType !== 'filter' || lastQueryFilter !== this.value) return

    const getLabelData = await labels()

    let labelValues = getLabelData.map(label => label.value)

    // If there are no labels, use the default labels
    if (labelValues.length === 0) {
      labelValues = this.defaultLabels
    }

    const filteredLabels = filterSuggestions(labelValues, lastQueryValue)

    for (const label of filteredLabels) {
      this.dispatchEvent(new FilterItem({filter: 'label', value: label}))
    }
  }
}

document.addEventListener('query-builder:request-provider', (event: Event) => {
  const target: QueryBuilderElement | null = event.target as QueryBuilderElement
  if (!target || target.id !== searchInputId) return

  new SearchItemProvider(event.target as QueryBuilderElement)
  new AuthorProvider(event.target as QueryBuilderElement)
  new CategoryProvider(event.target as QueryBuilderElement)
  new StateProvider(event.target as QueryBuilderElement)
  new LabelProvider(event.target as QueryBuilderElement)
})
