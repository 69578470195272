import {controller, target} from '@github/catalyst'
import {remoteForm} from '@github/remote-form'

@controller
class DiscussionSpotlightPreviewElement extends HTMLElement {
  @target spotlightElement: HTMLElement

  updatePreview(event: Event) {
    const currentOption = event.currentTarget as HTMLInputElement
    const newStyle = currentOption.getAttribute('style')!
    this.spotlightElement.setAttribute('style', newStyle)
  }
}

remoteForm('.js-spotlight-notice-dismiss', async function (form, send) {
  await send.text()
  const notice = form.closest<HTMLElement>('.js-notice')!
  notice.remove()

  if (document.querySelector('.js-discussions-categories')) {
    const categories_notice = document.querySelector<HTMLElement>('.js-discussions-categories')!
    categories_notice.classList.remove('v-hidden')
  }
})
