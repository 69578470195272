import {fromEvent} from '@github-ui/subscription'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

observe('.js-optimistic-reaction-render-button', {
  subscribe: el => fromEvent(el, 'click', handleReactionEvent, {capture: false}),
})

function handleReactionEvent(event: Event) {
  const currentTarget = event.currentTarget as HTMLElement

  if (currentTarget) {
    const renderer = new OptimisticReactionRenderer(currentTarget)

    // Defer the render until after the form values are collected for any remoteForm
    setTimeout(() => {
      renderer.render()
    }, 1)
  }
}

class OptimisticReactionRenderer {
  clickedReactionButton: HTMLElement

  constructor(clickedReactionButton: HTMLElement) {
    this.clickedReactionButton = clickedReactionButton
  }

  render() {
    if (!this.isReactionAlreadyRendered) {
      return this.addNewReactionGroup()
    }

    if (this.isAddingReaction) {
      this.addReactionToGroup()
    } else {
      this.removeReactionFromGroup()
    }
  }

  addReactionToGroup() {
    if (this.existingReactionGroupCountContainer && this.existingReactionGroup) {
      this.existingReactionGroup.removeAttribute('hidden')
      this.existingReactionGroup.classList.add('user-has-reacted')
      this.existingReactionGroupCountContainer.textContent = `${this.existingReactionGroupCount + 1}`
      this.addReactionToPopoverReactionButtons()
    }
  }

  /**
   * Focus on the next reaction if its present, or the previous one.
   * Reactions are indexed by their order from [`Emotion.all`](https://github.com/github/github/blob/702571a7f70b3e7bf9ad8bd753ca3acbee1b02bf/packages/issues/app/models/emotion.rb#L19) on the
   * server, so they might not be something like `[1, 2, 5, 6, 7]` rather than `[1,2,3]`
   */
  focusOnAdjacentReaction() {
    const getIndex = (button: HTMLElement) => {
      const index = parseInt(button.getAttribute('data-button-index-position') || '')
      if (isNaN(index)) {
        return null
      } else {
        return index
      }
    }

    if (!this.existingReactionGroup) return

    const existingReactionIndex = getIndex(this.existingReactionGroup)
    if (!existingReactionIndex) return
    let previousReaction: HTMLElement | undefined
    let nextReaction: HTMLElement | undefined

    // for a set of indices like `[1, 2, 5, 6, 7]`, if the user picks `5` we want
    // previousReaction = 2 and nextReaction = 6
    for (const button of this.reactionGroupsContainer.querySelectorAll('button').values()) {
      const currentReactionIndex = getIndex(button)
      if (!currentReactionIndex) return
      if (currentReactionIndex < existingReactionIndex) {
        previousReaction = button
      }
      if (currentReactionIndex > existingReactionIndex) {
        nextReaction = button
        break
      }
    }

    if (nextReaction) {
      nextReaction.focus()
    } else {
      if (previousReaction) {
        previousReaction.focus()
      }
    }
  }

  removeReactionFromGroup() {
    if (this.existingReactionGroupCount === 1) {
      this.existingReactionGroup!.setAttribute('hidden', '')
      this.focusOnAdjacentReaction()
    }

    this.existingReactionGroupCountContainer!.textContent = `${this.existingReactionGroupCount - 1}`
    this.existingReactionGroup?.classList.remove('user-has-reacted')
    this.removeReactionFromPopoverReactionButtons()
  }

  addNewReactionGroup() {
    const newEmojiButton = this.newEmojiButtonFromTemplate()

    if (this.newEmojiButtonTemplateContainer && newEmojiButton && this.commentReactionsContainer) {
      if (this.nextButtonGroup) {
        this.reactionGroupsContainer.insertBefore(newEmojiButton, this.nextButtonGroup)
      } else {
        this.reactionGroupsContainer.append(newEmojiButton)
      }

      newEmojiButton.classList.add(this.tooltipPositionClass)
      this.addReactionToPopoverReactionButtons()
      newEmojiButton.focus()

      if (this.commentReactionsContainer.classList.contains('d-md-none')) {
        this.commentReactionsContainer.classList.add('has-reactions')
      }
    }
  }

  newEmojiButtonFromTemplate() {
    if (this.newEmojiButtonTemplateContainer) {
      const templateContainerContent = this.newEmojiButtonTemplateContainer.content
      const templateContainerButton = templateContainerContent.querySelector('.js-reaction-group-button')

      if (templateContainerButton) return templateContainerButton.cloneNode(true) as HTMLButtonElement
    }
  }

  get nextButtonGroup() {
    if (this.newEmojiButtonFromTemplatePosition !== undefined && this.newEmojiButtonFromTemplatePosition !== null) {
      const reactionButtons = this.reactionGroupsContainer.children

      for (const button of reactionButtons) {
        const buttonIndexPosition = Number(button.getAttribute('data-button-index-position')!)

        if (buttonIndexPosition > this.newEmojiButtonFromTemplatePosition) return button
      }
    }
  }

  get commentContainer() {
    return this.clickedReactionButton.closest<HTMLElement>('.js-comment')!
  }

  get buttonsContainer() {
    return this.commentContainer.querySelector<HTMLElement>('.js-reaction-buttons-container')!
  }

  get reactionContent() {
    return this.clickedReactionButton.getAttribute('data-reaction-content')!
  }

  get reactionGroup() {
    return this.buttonsContainer.querySelector(`.js-comment-reactions-options g-emoji[alias='${this.reactionContent}'`)
  }

  get existingReactionGroup() {
    if (this.reactionGroup) return this.reactionGroup.closest<HTMLElement>('.js-reaction-group-button')!
  }

  get existingReactionGroupCountContainer() {
    if (this.existingReactionGroup) {
      return this.existingReactionGroup.querySelector('.js-discussion-reaction-group-count')
    }
  }

  get newEmojiButtonTemplateContainer() {
    return document.getElementById(`emoji-reaction-button-template-${this.reactionContent}`) as HTMLTemplateElement
  }

  get commentReactionsContainer() {
    return this.buttonsContainer.closest<HTMLElement>('.js-reaction-buttons-container')!
  }

  get reactionGroupsContainer() {
    return this.buttonsContainer.querySelector<HTMLElement>('.js-comment-reactions-options')!
  }

  get isReactionAlreadyRendered() {
    return !!this.reactionGroup
  }

  get isAddingReaction() {
    if (this.existingReactionGroup) {
      return !this.existingReactionGroup.classList.contains('user-has-reacted')
    }
  }

  get existingReactionGroupCount() {
    if (this.existingReactionGroupCountContainer) return Number(this.existingReactionGroupCountContainer.innerHTML)
    return 0
  }

  get newEmojiButtonFromTemplatePosition() {
    const newEmojiButtonFromTemplate = this.newEmojiButtonFromTemplate()

    if (newEmojiButtonFromTemplate) {
      return Number(newEmojiButtonFromTemplate.getAttribute('data-button-index-position')!)
    }
  }

  get tooltipPositionClass() {
    return this.buttonsContainer.querySelectorAll('button').length > 1 ? 'tooltipped-s' : 'tooltipped-se'
  }

  addReactionToPopoverReactionButtons() {
    const popovers = this.commentContainer.querySelectorAll('.js-add-reaction-popover')

    for (const popover of popovers) {
      const button = popover.querySelector<HTMLInputElement>(`button[data-reaction-content="${this.reactionContent}"]`)!
      button.classList.add('user-has-reacted')
      // make it say unreact
      if (!button.value.match(/unreact/)) {
        button.value = button.value.replace(/react/, 'unreact')
      }
      button.setAttribute('aria-checked', 'true')
    }
  }

  removeReactionFromPopoverReactionButtons() {
    const popovers = this.commentContainer.querySelectorAll('.js-add-reaction-popover')

    for (const popover of popovers) {
      const button = popover.querySelector<HTMLInputElement>(`button[data-reaction-content="${this.reactionContent}"]`)!
      button.classList.remove('user-has-reacted')
      // make it say react
      if (button.value.match(/unreact/)) {
        button.value = button.value.replace(/unreact/, 'react')
      }
      button.setAttribute('aria-checked', 'false')
    }
  }
}
