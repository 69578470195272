// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {ready} from '@github-ui/document-ready'

const observer = new IntersectionObserver(
  function (entries) {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        ;(entry.target as HTMLElement).hidden = true
      }
    }
  },
  {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  },
)

observe('.js-whats-new', el => observer.observe(el))

on('click', '.js-whats-new-state', e => {
  ;((e.target as HTMLElement)?.parentNode as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start'})
})

const showWhatsNew = () => {
  const el = document.querySelector('.js-whats-new-state')
  if (!el) return
  ;(el as HTMLElement).hidden = false
}

document.addEventListener('turbo:load', showWhatsNew)
;(async function () {
  await ready
  // Delay showing it so that the parent's InteractionObserver can fire first in case the user is scrolled
  setTimeout(showWhatsNew, 1000)
})()
