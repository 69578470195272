import type {Kicker} from '@github/remote-form'
import {fromEvent} from '@github-ui/subscription'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {remoteForm} from '@github/remote-form'

observe('.js-discussions-goto-answer-button', {
  add: el => {
    try {
      const href = el.getAttribute('href')
      const answerElement = document.querySelector<HTMLButtonElement>(href!)

      if (answerElement) {
        fromEvent(
          el,
          'click',
          e => {
            if (href) {
              e.preventDefault()
              let blockScrollOption: 'center' | 'start' = 'center'

              // If the answer's height is greater than or equal to the windows
              // height, we want to use the `start` option which aligns the top of
              // the answer with the top of the viewport
              if (answerElement.getBoundingClientRect().height >= window.innerHeight) {
                blockScrollOption = 'start'
              }
              answerElement.scrollIntoView({behavior: 'smooth', block: blockScrollOption})

              // Set focus and tab index to actual comment container
              const answerComment = answerElement.querySelector<HTMLElement>('.js-targetable-element')!
              if (answerComment) {
                answerComment.tabIndex = 0
                answerComment.focus({preventScroll: true})
              }
            }
          },
          {capture: true},
        )
      }
    } catch (e) {
      if (e instanceof DOMException) {
        // ignore
      } else {
        throw e
      }
    }
  },
})

observe('.js-discussions-title-container', {
  add: element => {
    const answeredPillButton = element.querySelector<HTMLElement>('.js-discussions-goto-answer-button')

    if (answeredPillButton) {
      removeMarkAsAnswerButtons()
    } else {
      addMarkAsAnswerButtons()
    }
  },
})

function removeMarkAsAnswerButtons() {
  const discussion = document.querySelector<HTMLElement>('.js-discussion')

  if (discussion) {
    discussion.classList.add('answered')
  }
}

function addMarkAsAnswerButtons() {
  const discussion = document.querySelector<HTMLElement>('.js-discussion')

  if (discussion) {
    discussion.classList.remove('answered')
  }
}

remoteForm('.js-discussion-comment-mark-answer', async function (form, wants) {
  await replaceAnswerForm(form, wants)
})

remoteForm('.js-discussion-comment-unmark-answer', async function (form, wants) {
  await replaceAnswerForm(form, wants)
})

async function replaceAnswerForm(form: HTMLFormElement, wants: Kicker) {
  const response = await wants.html()

  const commentContainer = form.closest('.js-nested-comment-container')
  if (commentContainer?.classList.contains('color-bg-success')) {
    commentContainer.classList.remove('color-bg-success')
    commentContainer.classList.remove('timeline-child-comment-answer-border')
  } else {
    commentContainer?.classList.add('color-bg-success')
    commentContainer?.classList.add('timeline-child-comment-answer-border')
  }

  let target: Element = form
  const container = form.closest('[data-replace-remote-form-target]')
  if (container) {
    const id = container.getAttribute('data-replace-remote-form-target')
    target = id ? document.getElementById(id)! : container
  }
  target.replaceWith(response.html)
}
