// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('click', '.js-discussion-comment-thread-expand-button', function ({currentTarget}) {
  const commentContainer = commentContainerFor(currentTarget as HTMLElement)
  if (!commentContainer) return

  conditionallyOpenEditFormAfterLoad(commentContainer)
})

function conditionallyOpenEditFormAfterLoad(element: HTMLElement) {
  if (isReplyFormOpen(element)) {
    element.setAttribute('data-open-edit-form-after-load', 'true')
  }
}

function commentContainerFor(element: HTMLElement) {
  return element.closest<HTMLElement>('.discussion-timeline-item.js-comment-container')
}

function isReplyFormOpen(element: HTMLElement) {
  const replyForm = element.querySelector<HTMLButtonElement>('.js-inline-comment-form-container')
  if (!replyForm) return false

  return replyForm.classList.contains('open')
}
