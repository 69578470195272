// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {parseHTML} from '@github-ui/parse-html'

const sendRequest = async (url: string, csrfToken: string, option_id: string) => {
  const form = new FormData()
  form.append('option_id', option_id)

  return await fetch(url, {
    body: form,
    method: 'POST',
    mode: 'same-origin',
    headers: {
      'Scoped-CSRF-Token': csrfToken,
    },
  })
}

class PollOptionsElement {
  optionsElement: HTMLElement | null

  constructor(optionsElement: HTMLElement | null) {
    this.optionsElement = optionsElement
  }
  checkedValue(): string | undefined {
    const checked = this.optionsElement?.querySelector<HTMLInputElement>('.js-discussion-poll-option:checked')
    return checked?.value
  }

  hide(): void {
    if (this.optionsElement) this.optionsElement.hidden = true
    this.optionsElement?.setAttribute('aria-hidden', 'true')
  }

  show(): void {
    if (this.optionsElement) this.optionsElement.hidden = false
    this.optionsElement?.setAttribute('aria-hidden', 'false')
  }
}

class PollResultsElement {
  resultsElement: HTMLElement | null

  constructor(resultsElement: HTMLElement | null) {
    this.resultsElement = resultsElement
  }

  hide(): void {
    if (this.resultsElement) this.resultsElement.hidden = true
    this.resultsElement?.setAttribute('aria-hidden', 'true')
  }

  show(): void {
    if (this.resultsElement) this.resultsElement.hidden = false
    this.resultsElement?.setAttribute('aria-hidden', 'false')
  }
}

class PollFormElement {
  pollForm: HTMLElement
  resultsElement: PollResultsElement
  optionsElement: PollOptionsElement
  showResultsButton: HTMLElement | null
  hideResultsButton: HTMLElement | null
  voteButton: HTMLElement | null
  voteUrl: string
  pollUrl: string
  pollLocked: string
  pollQuestion: HTMLDivElement | null

  constructor(pollForm: HTMLElement) {
    this.pollForm = pollForm
    this.resultsElement = new PollResultsElement(pollForm.querySelector<HTMLElement>('.js-discussion-poll-results'))
    this.optionsElement = new PollOptionsElement(pollForm.querySelector<HTMLElement>('.js-discussion-poll-options'))
    this.hideResultsButton = pollForm.querySelector<HTMLElement>('.js-poll-hide-results')
    this.showResultsButton = pollForm.querySelector<HTMLElement>('.js-poll-show-results')
    this.voteButton = pollForm.querySelector<HTMLElement>('.js-discussion-poll-vote-button')
    this.voteUrl = this.pollForm.getAttribute('data-vote-url') || ''
    this.pollUrl = this.pollForm.getAttribute('data-poll-url') || ''
    this.pollLocked = this.pollForm.getAttribute('data-poll-locked') || ''
    this.pollQuestion = this.pollForm.querySelector<HTMLDivElement>('#poll-question')
  }

  getCsrfInputValue(): string {
    const csrfInput = this.pollForm.querySelector<HTMLInputElement>('.js-data-url-post-csrf')
    return csrfInput ? csrfInput.value : ''
  }

  hideResults(): void {
    this.resultsElement.hide()
    this.optionsElement.show()
    if (this.hideResultsButton) this.hideResultsButton.hidden = true
    this.hideResultsButton?.setAttribute('aria-hidden', 'true')
    if (this.showResultsButton) this.showResultsButton.hidden = false
    this.showResultsButton?.setAttribute('aria-hidden', 'false')
    if (this.pollLocked === 'true') {
      this.voteButton?.setAttribute('aria-disabled', 'true')
    } else {
      this.voteButton?.setAttribute('aria-disabled', 'false')
    }
    if (this.pollQuestion) {
      if (this.pollQuestion.textContent)
        this.pollQuestion.textContent = this.pollQuestion.textContent.replace('Results: ', '')
      this.pollQuestion.focus()
    }
  }

  showResults(): void {
    this.resultsElement.show()
    this.optionsElement.hide()
    if (this.hideResultsButton) this.hideResultsButton.hidden = false
    this.hideResultsButton?.setAttribute('aria-hidden', 'false')
    if (this.showResultsButton) this.showResultsButton.hidden = true
    this.showResultsButton?.setAttribute('aria-hidden', 'true')
    this.voteButton?.setAttribute('aria-disabled', 'true')
    if (this.pollQuestion) {
      this.pollQuestion.textContent = `Results: ${this.pollQuestion.textContent}`
      this.pollQuestion.focus()
    }
  }
}

class Poll {
  pollFormElement: PollFormElement

  constructor(pollFormElement: PollFormElement) {
    this.pollFormElement = pollFormElement
    this.bindHideResultsClick(pollFormElement.hideResultsButton)
    this.bindShowResultsClick(pollFormElement.showResultsButton)
    this.bindVoteClick(pollFormElement.voteButton)
  }

  bindHideResultsClick(button: HTMLElement | null): void {
    button?.addEventListener('click', async () => {
      this.hideResults()
    })
  }

  bindShowResultsClick(button: HTMLElement | null): void {
    button?.addEventListener('click', async () => {
      this.showResults()
    })
  }

  bindVoteClick(button: HTMLElement | null): void {
    button?.addEventListener('click', async () => {
      this.vote()
    })
  }

  async vote(): Promise<void> {
    const checkedValue = this.pollFormElement.optionsElement.checkedValue()
    if (checkedValue) {
      const response = await sendRequest(
        this.pollFormElement.voteUrl,
        this.pollFormElement.getCsrfInputValue(),
        checkedValue,
      )
      if (response.ok) {
        try {
          const pollResponse = await fetch(this.pollFormElement.pollUrl, {headers: {Accept: 'text/html'}})
          const html = await pollResponse.text()
          this.pollFormElement.pollForm.replaceWith(parseHTML(document, html))
          const pollQuestion = document.querySelector<HTMLDivElement>('#poll-question')!
          if (pollQuestion) {
            pollQuestion.textContent = `Results: ${pollQuestion.textContent}`
            pollQuestion.focus()
          }
        } catch {
          return
        }
      }
    }
  }

  hideResults(): void {
    this.pollFormElement.hideResults()
  }
  showResults(): void {
    this.pollFormElement.showResults()
  }
}

observe('.js-discussion-poll-component', discussionPollElement => {
  new Poll(new PollFormElement(discussionPollElement as HTMLElement))
})
