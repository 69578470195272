// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

observe('.js-remove-error-state-on-click > details', {
  add: element => conditionallyReselectCategory(element as HTMLElement),
})

function conditionallyReselectCategory(element: HTMLElement) {
  const categoryId = document.getElementById('discussion_category_id')?.getAttribute('value')
  if (!categoryId) return

  const selectedCategoryButton = element.querySelector<HTMLButtonElement>(`.discussion-category-${categoryId}`)
  if (!selectedCategoryButton) return

  selectedCategoryButton.click()
}
