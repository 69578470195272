import {TemplateInstance} from '@github/template-parts'
import {fromEvent} from '@github-ui/subscription'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {onKey} from '@github-ui/onfocus'
import {hasDirtyFields} from '@github-ui/has-interactions'

type TemplateEditFormReplacements = {
  bodyVersion?: string | null
  outerDivClasses?: string | null
  repositoryId?: string | null
  targetBody?: string | null
  targetType?: string | null
  textareaId?: string | null
  slashCommandUrl?: string | null
  pollEditUrl?: string | null
}

observe('.js-discussions-comment-edit-button', {
  subscribe: element => fromEvent(element, 'click', handleClick, {capture: false, once: true}),
})

observe('.js-task-list-container .contains-task-list', {
  add: async element => conditionallyRenderEditForm(element as HTMLElement),
})

observe('.js-discussion-poll-form-component', {
  subscribe: element => fromEvent(element, 'pollOptionRemoved', handlePollModificationEvent),
})

onKey('keyup', '.js-discussion-poll-form-component input', handlePollModificationEvent)

async function conditionallyRenderEditForm(element: HTMLElement) {
  const commentContainer = element.closest<HTMLElement>('.js-comment')
  if (!commentContainer) {
    return
  }

  const targetContainer = editFormTargetContainer(commentContainer) as HTMLElement

  if (targetContainer) {
    const isPreviewContainer = targetContainer.querySelector<HTMLElement>('.js-preview-body')
    if (isPreviewContainer) return

    const url = targetContainer.getAttribute('data-permissions-url')
    const type = editFormTemplateReplacements(targetContainer).targetType

    if (url && type) {
      const isUpdateable = await fetchAndReturnUpdatePermission(url, type)

      if (isUpdateable) {
        insertEditForm(commentContainer)
        insertMarkdownToolbar(commentContainer)
      }
    }
  }
}

async function fetchAndReturnUpdatePermission(url: string, type: string) {
  const response = await fetch(url, {headers: {Accept: 'application/json'}})
  const json = await response.json()

  return json[type]['update']
}

function handleClick(event: Event) {
  const currentTarget = event.currentTarget as HTMLElement
  const commentContainer = currentTarget.closest<HTMLElement>('.js-comment')!

  insertEditForm(commentContainer)
  insertMarkdownToolbar(commentContainer)
  insertPollEditForm(commentContainer)
}

function handlePollModificationEvent(event: Event) {
  const currentTarget = event.currentTarget as HTMLInputElement
  const commentContainer = currentTarget.closest('.js-comment') as HTMLElement

  if (commentContainer) {
    const editPollFormContainer = commentContainer.querySelector('.js-discussion-poll-form-component')
    if (editPollFormContainer) {
      toggleFormActionVariant(commentContainer, hasDirtyFields(editPollFormContainer))
    }
  }
}

function insertMarkdownToolbar(commentContainer: HTMLElement) {
  const targetContainer = commentContainer.querySelector('.js-discussions-markdown-toolbar-placeholder:last-child')

  if (targetContainer) {
    const templateReplacements = {textareaId: targetContainer.getAttribute('data-textarea-id')}

    insertTemplate(
      'template.js-discussions-markdown-toolbar-template',
      targetContainer as HTMLElement,
      templateReplacements,
    )
  }
}

function insertPollEditForm(commentContainer: HTMLElement) {
  const targetContainer = commentContainer.querySelector('.js-discussions-poll-edit-placeholder')

  if (targetContainer) {
    const pollEditUrl = targetContainer.getAttribute('data-poll-edit-url')
    if (pollEditUrl === '') {
      return
    }
    const templateReplacements = {pollEditUrl}
    insertTemplate('template.js-discussions-poll-edit-template', targetContainer as HTMLElement, templateReplacements)
  }
}

function insertEditForm(commentContainer: HTMLElement) {
  const targetContainer = editFormTargetContainer(commentContainer) as HTMLElement

  if (targetContainer) {
    const templateReplacements = editFormTemplateReplacements(targetContainer)
    insertTemplate('template.js-discussions-edit-form-template', targetContainer, templateReplacements)
    insertEditFormActions(commentContainer, templateReplacements)
  }
}

function insertEditFormActions(commentContainer: HTMLElement, templateReplacements: TemplateEditFormReplacements) {
  const formActionsContainer = editFormActionsContainer(commentContainer) as HTMLElement

  if (formActionsContainer) {
    insertTemplate('template.js-discussions-edit-actions-template', formActionsContainer, templateReplacements)
  }
}

function insertPollEditConfirmationPrompt(commentContainer: HTMLElement) {
  const formActionsContainer = editFormActionsContainer(commentContainer) as HTMLElement

  if (formActionsContainer) {
    insertTemplate('template.js-discussions-poll-confirmation-template', formActionsContainer, {})
  }
}

function toggleFormActionVariant(commentContainer: HTMLElement, showConfirmationPrompt: boolean) {
  const targetContainer = editFormTargetContainer(commentContainer) as HTMLElement
  const currentVariantIsConfirmation = targetContainer.getAttribute('data-poll-confirmation') === 'true'

  // If there's nothing to toggle, just return instead of inserting the template again.
  if (currentVariantIsConfirmation === showConfirmationPrompt) {
    return
  }

  if (showConfirmationPrompt) {
    insertPollEditConfirmationPrompt(commentContainer)
    targetContainer.setAttribute('data-poll-confirmation', 'true')
  } else {
    const templateReplacements = editFormTemplateReplacements(targetContainer)
    insertEditFormActions(commentContainer, templateReplacements)
    targetContainer.setAttribute('data-poll-confirmation', 'false')
  }
}

function editFormTargetContainer(commentContainer: HTMLElement) {
  return commentContainer.querySelector('.js-edit-comment-form-container')
}

function editFormActionsContainer(commentContainer: HTMLElement) {
  return commentContainer.querySelector('.js-discussions-edit-form-actions')
}

function editFormTemplateReplacements(targetContainer: HTMLElement) {
  return {
    bodyVersion: targetContainer.getAttribute('data-body-version'),
    outerDivClasses: targetContainer.getAttribute('data-outer-div-classes'),
    repositoryId: targetContainer.getAttribute('data-repository-id'),
    targetBody: targetContainer.getAttribute('data-target-body'),
    targetType: targetContainer.getAttribute('data-target-type'),
    textareaId: targetContainer.getAttribute('data-textarea-id'),
    slashCommandUrl: targetContainer.getAttribute('data-slash-command-url'),
    pollEditUrl: targetContainer.getAttribute('data-poll-edit-url'),
  }
}

function insertTemplate(
  templateSelector: string,
  targetContainer: HTMLElement,
  templateReplacements: TemplateEditFormReplacements,
) {
  const template = document.querySelector<HTMLTemplateElement>(templateSelector)!

  if (template) {
    targetContainer.textContent = ''
    targetContainer.appendChild(new TemplateInstance(template, templateReplacements))
  }
}
